<template>
<div>
    <v-img :src="require('@/assets/dani2.png')" style="width:100%">
    <v-layout fill-height align-center>
      <v-container>
        <v-row align="center" justify="center" class="white--text">
        </v-row>
      </v-container>
    </v-layout>
  </v-img>
  <v-container>
    <h2>
      <div id="app">
  <v-app id="inspire">
    <v-timeline>
      <v-timeline-item
        v-for="(year, i) in years"
        :key="i"
        :color="year.color"
        small
      >
        <template v-slot:opposite>
          <span
            :class="`headline font-weight-light mb-4 ${year.color}--text`"
            v-text="year.year"
          ></span>
        </template>
        <div class="py-4">
          <h2 :class="`headline font-weight-medium mb-4 ${year.color}--text`">
            {{year.title}}
          </h2>
          <div>
            <subtitle-1 :class="`font-weight-light`">
            {{year.text}}
            </subtitle-1>
          </div>
        </div>
      </v-timeline-item>
    </v-timeline>
  </v-app>
</div>
    </h2>
  </v-container>
  </div>
</template>

<script>
export default {
  data: () => ({
    years: [
      {
        color: '#395B67',
        year: '1991',
        title: 'Geburt',
        text: 'geboren am 20. August in Oberviechtach'
      },
      {
        color: '#395B67',
        year: '1998',
        title: 'Volksschule Eslarn',
        text: 'Besuch der Grundschule in Eslarn'
      },
      {
        color: '#395B67',
        year: '2002',
        title: 'Ortenburg-Gymnasium Oberviechtach',
        text: 'Besuch des Gymnasiums in Oberviechtach, Abitur 2011'
      },
      {
        color: '#395B67',
        year: '2011',
        title: 'Universität Bayreuth',
        text: 'Studium Rechtswissenschaften an der Universität Bayreuth mit wirtschaftswissenschaftlicher Zusatzausbildung'
      },
      {
        color: '#395B67',
        year: '2012 - 2016',
        title: 'Studentische Hilskraft',
        text: 'bei Prof. Dr. Bernd Kannowski (Lehrstuhl für Bürgerliches Recht und Rechtsgeschichte)'
      },
      {
        color: '#395B67',
        year: '2013',
        title: 'Tutor für Strafrecht Besonderer Teil I',
        text: 'bei Prof. Dr. Nikolaus Bosch (Lehrstuhl für Strafrecht, Strafprozessrecht und Wirtschaftsstrafrecht)'
      },
      {
        color: '#395B67',
        year: '2017',
        title: 'Erste Juristische Prüfung',
        text: 'Verleihung des akademischen Grades "Diplom-Jurist", Abschluss der wirtschaftswissenschaftlichen Zusatzausbildung mit der Berechtigung zur Führung des Titels "Wirtschaftsjurist (Univ. Bayreuth)"'
      },
      {
        color: '#395B67',
        year: '2017 - 2019',
        title: 'Rechtsreferendariat',
        text: 'Rechtsreferendar beim Freistaat Bayern, darunter u.a. Mitarbeit in der Rechtsabteilung der Grammer AG'
       
      },
      {
        color: '#395B67',
        year: '2018',
        title: 'Ausbildung zum Wirtschaftsmediator',
        text: 'Teilnahme an der Münchener Ausbildung zum Wirtschaftsmediator (Eidenmüller/Hacke/Fries) und Berechtigung zur Führung des Titels "Mediator"'
       
      },
      {
        color: '#395B67',
        year: '2019',
        title: 'Zweite Juristische Staatsprüfung',
        text: 'Abschluss der juristischen Ausbildung und Berechtigung zur Führung des Titels "Rechtsassessor"'
       
      },
      {
        color: '#395B67',
        year: '15.07.2019',
        title: 'Zulassung zur Rechtsanwaltschaft bei der Rechtsanwaltskammer Nürnberg',
        text: 'als Rechtsanwalt und als Syndikusrechtsanwalt (Rechtsanwalt)'
      },
      {
        color: '#395B67',
        year: 'seit 2019',
        title: 'Berufsausübung',
        text: 'als Syndikusrechtsanwalt bei der Gruber Unternehmensgruppe, als Rechtsanwalt in eigener Kanzlei'
      },
      {
        color: '#395B67',
        year: 'seit 2020',
        title: 'Dozententätigkeit für den Arbeitskreis "Gestörter Bauablauf"',
        text: 'beim Bundesverband in den Gewerken Trockenbau und Ausbau e.V.'
      },
    ],
  })
}
</script>